.stay-tunned {
  .form-wrapper {
    @include media-breakpoint-up($grid-float-breakpoint) {
      width: 50%;
    }
  }


}
.btn-default{
  border: 1px solid #ff7100;
  color: #ff7100;
}
.apply-now{
color: #ff7100;
font-weight: 600;}