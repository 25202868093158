.alter3-header {
  .container {
    padding-bottom: 20rem;

    @include media-breakpoint-up($grid-float-breakpoint) {
      padding-bottom: 15rem;
    }
  }

  .animation {
    &-shape {
      position: absolute;

      &.shape {
        &-triangle {
          top: 50%;
          left: 50%;

          div {
            opacity: .1;
            @include triangle-variant(15px, $white);
          }
        }

        &-ring {
          top: 75%;
          left: 25%;

          div {
            @include ring-variant(10px, 5px, $blue-light);
          }
        }

        &-circle {
          top: 20%;
          left: 50%;

          div {
            @include circle-variant(15px, rgba(theme-color("light"), 0.3));
          }
        }

        &-diamond {
          top: 35%;
          left: 25%;

          div {
            opacity: .5;
            @include diamond-variant(10px, theme-color("alternate"));
          }
        }
      }
    }
  }

  .static {
    &-shape {
      position: absolute;

      &.shape {
        &-ring {
          &-1 {
            @include ring-variant(40px, 15px, rgba($white, .5));
            top: 15%;
            left: 0;
            transform: translateX(-50%);
          }

          &-2 {
            @include ring-variant(60px, 15px, rgba($white, .5));
            top: 5%;
            right: 0;
            transform: translateX(50%);
          }
        }

        &-circle {
          &-1 {
            @include circle-variant(120px, rgba($white, .09));
            top: 22%;
            left: 22%;
          }
        }
      }

      &.pattern {
        &-dots {
          &-1 {
            top: 0;
            right: 0;
            width: 250px;
            height: 50%;
            @include pattern-dots(3px, 30px, rgba($white, .1));
          }

          &-2 {
            left: 0;
            bottom: 0;
            width: 150px;
            height: 20%;
            @include pattern-dots(3px, 30px, rgba($white, .1));
          }
        }
      }

      &.ghost-shape {
        opacity: .3;
        width: 100%;
        height: 100%;
        display: block;
        border-radius: $shape-bg-border-radius;

        &-1 {
          top: 0;
          transform: translate3D(30%, -93%, 0) skewX(35deg) rotate(-12deg);
          // background-color: $shape-bg-1--bg-color;
          background-color: #0062ab;
          max-height: $shape-bg-1--max-height;
          box-shadow: $shape-bg-1--box-shadow;
        }
      }
    }
  }
}
