/* 6.1 General styles ======================================================= */
section,
.section {
  position: relative;

  .container,
  .container-fluid {
    padding-top: $section-container-padding-y / 2;
    padding-bottom: $section-container-padding-x / 2;
    position: relative;

    &.bring-to-front {
      z-index: 9;
    }

    @include media-breakpoint-up($grid-float-breakpoint) {
      padding-top: $section-container-padding-y;
      padding-bottom: $section-container-padding-x;
    }

    @include media-breakpoint-down($grid-float-breakpoint-max) {
      overflow: hidden;
    }
  }

  .container-wide {
    overflow-x: hidden;

    .row {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  &.shadow {
    z-index: 1;
  }
}


.custom-files{
  position: relative;
}
.custom-files input{

  opacity: 0;
}
.custom-files span{
  position: absolute;
    left: 180px;
    top: 7px;
}
.custom-files:before{
  position: absolute;
  content: 'Upload';
  top: 0;
  left: 0;
  width: 160px;
  height: 40px;
  border-radius: 30px;
  color: #ff7200;
  background-color: #fff;
  border: 1px solid #ff7200;
  line-height: 36px;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
}

.thank-you{display: block;
margin: 0px 0;
text-align: center;
font-size: 42px;
color: green;
font-weight: 300;
}
.error-msg{
  display: block;
margin: 0px 0;
text-align: center;
font-size: 25px;
color: red;
font-weight: 300;
}
.textofpopup{
  font-size: 14px;
  text-align: center;
  display: block;
  margin-bottom: 25px;
}

.hs-head.modal-header{
  border-bottom: 0;
}
.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #3498db;
  width: 45px;
  height: 45px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  position: absolute;
    top: 44%;
    left: 44%;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.section-heading {
  // margin-bottom: $padding-default;
  margin-bottom: 45px;
  position: relative;

  h2 {
    margin-top: 0;
    margin-bottom: 0; //($padding-default / 2);
    font-weight: $font-weight-normal;
    font-size: $font-size-section-heading;
  }

  p {
    font-weight: $font-weight-light;
  }
  img.small-thumb{
    max-width: 38px;
  }

  address  +  p ,  address  +  p + p{

    display: inline-block;
    margin: 5px 0;
    line-height: 16px;
  
  }
}

.heading-line {
  &:after {
    content: "";
    display: block;
    margin-top: 32px;
    margin-bottom: 24px;
    width: 40px;
    height: 2px;
    background-color: $color-accent;
  }
}

.text-center {
  .heading-line {
    &:after {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.partners {
  img {
    @include media-breakpoint-up($grid-float-breakpoint) {
      opacity: 0.5;
      transform: scale(0.8);
      transition: all 0.3s ease-in-out;

      &:hover {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

.centered-screen {
  position: relative;

  .screen-highlight {
    bottom: 45px;
  }

  .message {
    bottom: 130px;
    z-index: 1;
  }
}
@media screen and (max-width:768px){
  .lead{
    font-size: 1em;
  }
}