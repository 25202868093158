.features-cant-miss {
  .bubble {
    z-index: 1;
    position: absolute;
    max-width: 60%;

    &-left {
      top: 18%;
    }

    &-right {
      right: $grid-gutter-width;
      top: 64%;
    }

    @include media-breakpoint-up($grid-float-breakpoint) {
      max-width: 50%;

      &-left {
        top: 21%;
      }

      &-right {
        right: $grid-gutter-width * 2;
      }
    }
  }

  .shape {
    position: absolute;

    &-circle {
      &-1 {
        left: 7%;
        top: 60%;

        div {
          @include circle-variant(150px, $fcm--circle1-bg-color);
        }

        @include media-breakpoint-up($grid-float-breakpoint) {
          left: 25%;
          top: 5%;
        }
      }

      &-2 {
        left: 60%;
        top: 70%;

        div {
          @include circle-variant(120px, $fcm--circle2-bg-color);
        }

        @include media-breakpoint-up($grid-float-breakpoint) {
          left: 22%;
          top: 23%;
        }
      }

      &-3 {
        left: 40%;
        top: 78%;

        div {
          @include circle-variant(120px, $fcm--circle3-bg-color);
        }
      }

      &-4 {
        left: 25%;
        top: 70%;

        div {
          @include circle-variant(50px, $fcm--circle4-bg-color);
        }
      }
    }

    &-triangle {
      top: 80%;
      right: 0;

      div {
        opacity: .15;
        @include triangle-variant(95px, $fcm--triangle-bg-color, $fcm--triangle2-bg-color);
        animation-duration: 50s;
      }
    }

    &.pattern {
      &-dots {
        &-1 {
          bottom: 0;
          left: 0;
          width: 150px;
          height: 50%;
          transform: translate3d(0, 50%, 0);
          @include pattern-dots(3px, 30px, $use-cases--dots-color);
        }
      }
    }
  }
}

@media screen and (max-width:768px){
  .features-cant-miss .shape-circle-1 {
    left: 95%;
    top: 75%;
}
.react-multiple-carousel__arrow{
  min-width: 24px;
  min-height: 24px;
  top: 0;
}
.react-multiple-carousel__arrow::before{
  font-size: 13px;
}
.react-multiple-carousel__arrow--left{
  left: calc(76% + 1px);
}

}